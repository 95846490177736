
import { defineComponent } from 'vue'
import InsurancePlanC from '../components/insurance-plan.vue'

export default defineComponent({
    name: 'InsurancePlan',
    components: {
        'insurance-plan-c': InsurancePlanC
    },
    data () {
        return {}
    },
    methods: {}
})
